(function (window) {
  const isNotificationClosed = window.localStorage.getItem('yotitleClosed');

  if (isNotificationClosed) {
    return;
  }

  document.querySelector('.yotitleNotification').classList.add('shown');

  const handleClick = () => {
    window.localStorage.setItem('yotitleClosed', true);
    document.querySelector('.yotitleNotification').classList.remove('shown');
    ym(49633516, 'reachGoal', document.location.host === 'uxfeedback.ru' ? 'closeYotitleBlock' : 'test_closeYotitleBlock');
  }

  const handleMenuClick = () => {
    if (!document.querySelector('#mainMenu.mobile').classList.contains('active')) {
      document.querySelector('.yotitleNotification').style = '';
    } else {
      document.querySelector('.yotitleNotification').style.display = 'none';
    }
  }

  document.querySelector('.yotitleNotification .closeIcon').onclick = handleClick;
  setTimeout(() => {
    document.querySelector('#mainMenu .menuIcon').addEventListener('click', handleMenuClick);
  }, 1000);

})(window);